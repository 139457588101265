<template>
  <div class="page form2">
    <h3>
      专栏详情
      <copy-link :url="'/pages/channel/detail?id=' + form.id"/>
    </h3>

    <el-tabs type="border-card" v-loading="loading">
      <el-tab-pane label="专栏详情">
        <el-form class="form" ref="form" :model="form" label-width="120px">
          <el-form-item label="专栏名称">
            <el-input v-model="form.title"/>
          </el-form-item>
          <el-form-item label="专栏头像">
            <input-image-single v-model="form.avatar"/>
          </el-form-item>
          <el-form-item label="专栏封面">
            <input-image-single v-model="form.cover"/>
          </el-form-item>
          <el-form-item label="专栏背景图">
            <input-image-single v-model="form.cover_full"/>
          </el-form-item>
          <el-form-item label="专栏描述">
            <el-input v-model="form.intro" type="textarea" autosize/>
          </el-form-item>
          <el-form-item label="专栏作者">
            {{ form.user.realname }}
          </el-form-item>
          <div v-if="id && $dbus.account.role === 'admin'">
            <el-form-item label="结算">
              <el-switch style="margin-right: 10px"
                         @change="onSettle" v-model="form.is_settled"
                         active-text="已结算" inactive-text="未结算"/>
              <span v-if="form.is_settled">上次结算日期：{{ form.datetime_settlement || '未结算过' }}</span>
              <span v-else>---</span>
            </el-form-item>
            <el-form-item prop="i_order" label="排序">
              <el-input-number v-model="form.i_order" :min="0"/>
              <div class="tip">数值越大，排序越靠前。</div>
            </el-form-item>
          </div>

          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="load">刷新</el-button>
            <a v-if="id" class="el-button el-button--medium" target="_blank"
               :href="`${$settings.host}/get_wechat_share_poster?xid=channel-${id}&from_uid=${form.user.id}`">推广海报下载</a>
            <el-button type="danger" @click="onRemove">删除</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="课程列表">
        <el-table class="content table"
                  :data="courses" fit highlight-current-row>
          <el-table-column label="课程名称" prop="title"/>
          <el-table-column label="添加时间" prop="datetime_created" width="160"/>
          <el-table-column>
            <template slot="header" slot-scope="scope">
              <el-select size="small" v-model="selected_id" filterable>
                <el-option v-for="u in objects_options" v-if="u.model === 'course'"
                           :label="u.title" :value="u.xid" :key="u.xid"/>
              </el-select>
              <el-button size="small" @click="onAddChanelContent">添加</el-button>
            </template>

            <template slot-scope="scope">
              <el-button-group>
                <router-link class="el-button el-button--small"
                             :to="`/course-editor?id=${scope.row.id}`">详情
                </router-link>
                <el-button size="small" @click="() => removeChanelContent('course-'+ scope.row.id)">删除</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>

      </el-tab-pane>
      <el-tab-pane label="帖子列表">
        <el-table class="content table"
                  :data="posts" fit highlight-current-row>
          <el-table-column label="帖子名称" prop="title"/>
          <el-table-column label="添加时间" prop="datetime_created" width="160"/>
          <el-table-column>
            <template slot="header" slot-scope="scope">
              <el-select size="small" v-model="selected_id" filterable>
                <el-option v-for="u in objects_options" v-if="u.model === 'post'" :label="u.title" :value="u.xid"
                           :key="u.xid"/>
              </el-select>
              <el-button size="small" @click="onAddChanelContent">添加</el-button>
            </template>

            <template slot-scope="scope">
              <el-button-group>
                <router-link class="el-button el-button--small"
                             :to="`/post-info?id=${scope.row.id}`">详情
                </router-link>
                <el-button size="small" @click="() => removeChanelContent('post-'+ scope.row.id)">删除</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>

      </el-tab-pane>
      <el-tab-pane label="专栏粉丝">
        <div>粉丝数：{{ fans.length }}</div>

        <el-table class="content table" :data="fans">
          <el-table-column label="UID" prop="user.id" width="120"/>
          <el-table-column label="姓名" width="230"
                           :formatter="row => row.user.realname || row.user.nickname"/>
          <el-table-column label="关注时间" prop="datetime_created" width="160"/>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="引流记录">
        <router-link style="margin: 10px" :to="`/user-info?id=${form.user.id}&tab=invitation`">
          现已迁移到【用户 -> 推广记录】点击前往
        </router-link>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'channel-info',
  data() {
    return {
      id: this.$route.query.id,
      loading: false,
      form: {
        user: {},
      },
      users: [],
      courses: [],
      posts: [],
      goods: [],
      selected_id: '',
      goods_options: [],
      posts_options: [],
      courses_options: [],
      fans: [],
      tg_records: {
        users: [],
        orders: [],
      },
      objects_options: [],
    }
  },
  mounted() {
    this.load()
  },
  computed: {},
  methods: {
    async load() {
      if (this.id) {
        this.loading = true
        this.$api.request('/channel/get_detail_full', {id: this.id}).then(data => {
          this.loading = false
          this.form = data
          this.fans = data.fans
          this.courses = data.courses
          this.posts = data.posts
          this.goods = data.goods
          let uid = this.form.user.id
          this.$api.request('/user/list_tg_records', {uid}).then(data => {
            this.tg_records = data
          })
        })
      } else {
        this.form = {
          title: this.$dbus.account.nickname + '的专栏',
          intro: '',
          uid: this.$dbus.account.id,
          user: this.$dbus.account,
        }
      }
      this.objects_options = await this.$api.request('/list_available_objects')
    },
    onSubmit() {
      if (this.id) {
        this.$api.request('/channel/update', {...this.form}).then(data => {
          this.load()
          this.$message.success('更新成功')
        })
      } else {
        this.$api.request('/channel/create', {...this.form}).then(data => {
          this.$router.push('/channel-info?id=' + data.id)
          this.$message.success('新专栏创建成功')
        })
      }
    },
    onAddChanelContent() {
      this.$api.request('/channel/add_content', {id: this.id, xid: this.selected_id}).then(data => {
        this.$message.success('添加成功')
        this.selected_id = ''
        this.load()
      })
    },
    removeChanelContent(xid) {
      this.$api.request('/channel/remove_content', {id: this.id, xid}).then(data => {
        this.$message.success('删除成功')
        this.load()
      })
    },

    onRemove() {
      this.$confirm('您确定要删除该专栏吗？').then(() => {
        this.$api.request('/channel/remove', {id: this.id}).then(data => {
          this.$message.success('删除成功')
          this.$router.push('/channel-list')
        })
      })
    },
    onSettle() {
      this.$confirm('确认变更结算状态？').then(() => {
        this.onSubmit()
      })
    }
  }
}
</script>
